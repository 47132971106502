/*doc
---
title: WAN Cards
name: 02-wan-cards
category: Layout-WAN Cards
---

```html_example
<div class="row">
    <div class="col-md-6 col-xl-6">
        <div class="card card-block port-1Gbps">
            <h3 class="card-title">WAN1 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN1</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>75.190.136.75</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>255.255.224.0</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>75.190.128.1</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>209.18.47.62</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-block port-disabled">
            <h3 class="card-title">WAN2 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN2</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>-</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-block port-2Gbps">
            <h3 class="card-title">WAN3 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN3</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>75.190.136.75</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>255.255.224.0</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>75.190.128.1</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>209.18.47.62</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-block port-not-connected">
            <h3 class="card-title">WAN4 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN4</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>-</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-block port-5Gbps">
            <h3 class="card-title">WAN5 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN5</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>-</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-block port-10Gbps">
            <h3 class="card-title">WAN6 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN6</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>-</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="card card-block port-10100Mbps">
            <h3 class="card-title">WAN4 Status</h3>
            <div class="table-responsive">
                <table class="table table-sm">
                    <tr>
                        <td><strong>Interface</strong></td>
                        <td>WAN4</td>
                    </tr>
                    <tr>
                        <td><strong>IP Address</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Subnet Mask</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>Default Gateway</strong></td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td><strong>DNS</strong></td>
                        <td>-</td>
                    </tr>
                </table>
            </div>
            <div class="row">
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-lock-open"></span>Release</button></div>
                <div class="col-sm-6"><button class="btn btn-secondary btn-block"><span class="icon icon-autorenew"></span>Renew</button></div>
            </div>
        </div>
    </div>
</div>
```

*/

.card[class*="port-"]:not([class*="port-settings"]) {
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    border-top: 1px solid $med-gray;
  }

}

.card.port-not-connected {
  border-color: #d3d3d3;

  &:after {
    border-color: #d3d3d3;
    color: $dark-gray;
    content: 'N/C';
    background: #f5f5f5;
  }

}

.card.port-connecting {
  border-color: $alert-warning-border;

  &:after {
    border-color: $alert-warning-border;
    color: $alert-warning-text;
    content: 'Connecting';
    background: $alert-warning-bg;
  }

}

.card.port-connected {
  border-color: $alert-success-border;

  &:after {
    border-color: $alert-success-border;
    color: $alert-success-text;
    content: 'Connected';
    background: $alert-success-bg;
  }

}

.card.port-disabled {
  border-color: $alert-danger-border;

  &:after {
    border-color: $alert-danger-border;
    color: $alert-danger-text;
    content: 'Disabled';
    background: $alert-danger-bg;
  }

}

.card.port-enabled {
  border-color: $alert-success-border;

  &:after {
    border-color: $alert-success-border;
    color: $alert-success-text;
    content: 'Enabled';
    background: $alert-success-bg;
  }

}

.card.cist.port-forwarding {
  border-color: $alert-success-border;

  &:after {
    border-color: $alert-success-border;
    color: $alert-success-text;
    content: 'Forwarding';
    background: $alert-success-bg;
  }

}

.card.cist.port-rooted {
  border-color: $alert-warning-border;

  &:after {
    border-color: $alert-warning-border;
    color: $alert-warning-text;
    content: 'Rooted';
    background: $alert-warning-bg;
  }

}

.card.cist.port-blocking {
  border-color: $alert-danger-border;

  &:after {
    border-color: $alert-danger-border;
    color: $alert-danger-text;
    content: 'Blocking';
    background: $alert-danger-bg;
  }

}

.card.cist.port-disabled {
  border-color: #d3d3d3;

  &:after {
    border-color: #d3d3d3;
    color: $dark-gray;
    content: 'Disabled';
    background: #f5f5f5;
  }

}

.card.port-1Gbps {
  border-color: $alert-success-border;

  &:after {
    border-color: $alert-success-border;
    color: $alert-success-text;
    content: '1 Gb/s';
    background: $alert-success-bg;
  }

}

.card.port-2Gbps {
  border-color: $alert-info-border;

  &:after {
    border-color: $alert-info-border;
    color: $alert-info-text;
    content: '2.5 Gb/s';
    background: $alert-info-bg;
  }

}

.card.port-5Gbps {
  border-color: #78909c;

  &:after {
    border-color: #78909c;
    color: #fff;
    content: '5 Gb/s';
    background: #90a4ae;
  }

}

.card.port-10Gbps {
  border-color: #212121;

  &:after {
    border-color: #212121;
    color: #fff;
    content: '10 Gb/s';
    background: #424242;
  }

}

.card.port-10Mbps,
.card.port-100Mbps {
  border-color: #FFA000;

  &:after {
    border-color: #FFA000;
    color: #fff;
    content: '10 Mb/s';
    background: #FFA000;
  }

}

.card.port-100Mbps {

  &:after {
    content: '100 Mb/s';
  }

}

.card[class*="ddns-"] {
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;

  p.status-message {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    text-align: center;
    padding: 5px;
    font-size: 12px;
    border-top: 1px solid $med-gray;
    margin-bottom: 0;
  }

}

.card.ddns-bad {
  border-color: $alert-danger-border;

  p.status-message {
    border-color: $alert-danger-border;
    color: $alert-danger-text;
    background: $alert-danger-bg;
  }

}

.card.ddns-good {
  border-color: $alert-success-border;

  p.status-message {
    border-color: $alert-success-border;
    color: $alert-success-text;
    background: $alert-success-bg;
  }

}

.card.ddns-wait {
  border-color: $alert-yellow-border;

  p.status-message {
    border-color: $alert-yellow-border;
    color: $alert-yellow-text;
    background: $alert-yellow-bg;
  }

}